.panel--profile {
  @extend .panel;

  height: 100%;
  margin: 0;

  .panel__body {
    background-color: $color-default;
    padding: $indent-l $indent-hor;

    display: flex;
    gap: $indent-s;

    .panel__intro {
      .actions {
        position: static;
        margin-top: $indent-xl;
        
        a {
          font-style: normal;
          color: $color-purple;
        }
      }

      .panel__intro__text {
        color: $color-grey;
    
        h2 {
          font-size: $font-size-l;
        }
    
        p {
          span {
            font-style: normal;
          }
        }
      }

      @media (max-width: $screen-xs) {
        .actions {
          margin-top: 0;
        }
      }
    }
  }
}