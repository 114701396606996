
.quote {
    font-weight: $font-weight-bold;
    font-style: italic;
    color: $color-purple;
    text-transform: none;
    margin-bottom: $indent-xxl;
    
    .quote__quotation_mark {
        position: relative;
        display: inline-block;
        margin: 0 0.5rem;
        line-height: $font-size-default;
    }
}
