
.onboarding__backdrop {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    background: $color-grey-80;

    @media (max-width: $screen-xs) {
        height: 100vh;

        .card--simple {
            background: transparent;
            @include mi-no-shadow;
        }
    }
}

.onboarding__backdrop--white {
    @extend .onboarding__backdrop;

    width: 100%;
    min-height: 100%;

    @include mi-flexbox();
    @include mi-flex-direction(column);
    @include mi-align-items(center);

    .card--simple {
        height: auto;
        min-height: $card-height;
        margin: 0 0 $indent-l;
        background: $color-default;
        color: $color-text;

        @include mi-flexbox();
        @include mi-flex-direction(column);
        @include mi-flex(1 0 auto);

        .card--simple {
            height: auto;
            margin: 0;
            padding-bottom: 0;
            background: transparent;
            color: $color-text;

            @include mi-no-shadow;

            h2 {
                color: $color-grey-60;

                span {
                    color: var(--color-theme);

                    + br {
                        display: none;
                    }
                }
            }

            ul,
            p {
                color: $color-text;
            }

            ul {
                text-align: left;
            }

            .button--expand {
                margin-bottom: $indent-m;
            }

            .card--simple__buttons {
                text-align: center;
            }

            h4 {
                color: $color-text;
                font-size: $font-size-l;
                font-weight: $font-weight-bold;
            }
        }

        .apply-job-offer {
            margin: 0 0 $indent-l;
            padding: $indent-l;
            background: $color-bg-darker;
            border-radius: $border-radius;

            @include mi-drop-shadow(0, 2px, 2px, 0, 0.3);

            .modal__step {
                display: none;
            }
        }

        @media (max-width: $screen-xs) {
            margin-bottom: 0;
        }

        h1 {
            color: var(--color-theme);
        }
    }

    @media (max-width: $screen-xs) {
        background: $color-default;

        .onboarding {
            .onboarding__skip_link {
                color: $color-text;
            }
        }

        .modal--vacancy__table {
            p {
                strong {
                    display: block;
                    margin: 0 0 $indent-s;
                }
            }
        }

        .bullet_list {
            text-align: center;

            li {
                span {
                    border-color: $color-border;

                    &.is-active {
                        background: $color-grey-10;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-xxxs) {
        .onboarding {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.onboarding {
    .bullet_list {
        margin: 0 0 $indent-xxl;
    }

    h1 {
        color: $color-purple;
        p {
            color: $color-purple;
        }
    }

    p {
        color: $color-text;
    }

    .card--simple {
        border-radius: 0;
        background: $color-background-light;

        .button--link {
            color: $color-purple;

            &:hover {
                color: $color-purple-dark;
            }
        }
    }

    .onboarding__image,
    .form__image {
        margin: 0 auto $indent-m;
    }

    .onboarding__content {
        @include mi-flexbox();
        @include mi-flex-direction(column);
        @include mi-flex(1 1 100%);

        .form--bg {
            .form__radio {
                .form__radio__input {
                    + .form__radio__label {
                        &:before {
                            background-image: url("#{$images-path}radio-sprite-dark.png");
                        }
                    }
                }
            }
        }
        .form__item {
            color: $color-text !important;
            .select__control {
                color: $color-text !important;
                border-bottom: 1px solid $color-border;

                .select__single-value {
                    color: $color-text !important;
                }

                .select__indicators {
                    color: $color-text !important;
                }
            }

            .form__input--text {
                color: $color-text !important;
                border-bottom: 1px solid $color-border;
            }

            .form__top_label { 
                left: 0;
            }

            .cv__radio {
                display: flex;
                flex-direction: column;
                gap: $indent-hor;
                padding: $indent-hor 13rem;

                .form__radio {
                    .form__radio__input {
                        + .form__radio__label {
                            &:before {
                                background-image: url("#{$images-path}radio-sprite-dark.png");
                            }
                        }
                    }

                    .radio__details {
                        font-size: $font-size-s;
                        padding-left: 3.5rem;
                    }
                }
            }
        }

        .cv__upload {
            input[type="file"] {
                display: none;
            }
            
            .cv {
                width: 100%;
                height: 20rem;
                margin-bottom: $indent-s;
                border: 1px $color-purple dashed;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                cursor: pointer;
                background: $color-background;

                .heading {
                    font-weight: $font-weight-bold;
                    color: $color-purple;
                }

                .link {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                    color: $color-purple;
                }
            }

            @media (max-width: $screen-md) {
                .cv {
                    padding: $indent-hor;
                }
            }

            .file_container {
                display: flex;
                margin-bottom: $indent-s;
                align-items: center;
                justify-content: space-between;
                color: $color-purple;

                .file_name {
                    display: flex;
                    align-items: center;
                    gap: $indent-xs;
                    
                    i {
                        width: 24px;
                        height: 24px;
                    }
                }

                .icon-close {
                    cursor: pointer;
                }
            }
        }
    }

    .onboarding__button_placeholder {
        height: 5rem;
    }

    .onboarding__skip_link {
        position: absolute;
        top: $indent-m;
        right: $indent-m;
        display: inline-block;
        line-height: $line-height-m;
        color: $color-default;
        font-size: $font-size-s;
        vertical-align: top;

        i {
            position: relative;
            float: right;
            right: 0;
            margin: 0 0 0 $indent-xs;
            line-height: $line-height-m;
            font-size: $font-size-icon;

            @include mi-transition(0.1s);
        }

        &:hover,
        &:active {
            cursor: pointer;

            i {
                right: -0.5rem;
            }
        }
    }

    .card--last {
        p {
            color: $color-grey-60;
        }
    }

    .button--alt.is-loading {
        padding-left: 7rem;
    }

    .form .form--small_margin {
        color: $color-default !important;

        .form__item {
            padding: 0 0 3rem 0;
        }
        .form__icon {
            top: 0.2rem;
        }
    }

    @media (max-width: $screen-xs) {
        min-height: 100vh;
        width: 100%;
        padding: $indent-xxl $indent-l 0;
        margin-bottom: 12rem;
        overflow-y: auto;

        @include mi-flexbox();
        @include mi-flex-direction(column);

        .onboarding__content {
            display: block;

            @include mi-flex(none);

            .form__item {
                .cv__radio {
                    display: flex;
                    flex-direction: column;
                    gap: $indent-hor;
                    padding: $indent-hor $indent-m;
                }
            }
        }

        .onboarding__skip_link {
            right: 0;
        }

        .card--simple__intro {
            display: block;
            height: auto;
            max-width: 100%;
            margin: 0 0 $indent-xl;

            @include mi-flex(none);
        }

        .card--simple {
            height: 100%;
            margin: 0;
            padding: $indent-l $indent-xl;

            @include mi-flexbox();
            @include mi-flex-direction(column);

            .card--simple__buttons {
                margin-top: auto;
            }

            .form {
                margin: $indent-xl auto 0;
                padding: 0;
            }

            .alert--box--error {
                margin: $indent-m auto 0;
            }
        }

        .card--bg_img,
        .card--last {
            margin-left: auto;
            margin-right: auto;

            .card__content {
                background: $color-default;
                color: $color-theme-dark;
            }

            .card__subject {
                color: var(--color-theme);
            }

            .card__text,
            p {
                color: $color-grey-60;
            }
        }

        .card--last {
            .card__content {
                height: auto;
            }
        }

        .bullet_list {
            margin: 0 0 $indent-m;
        }
    }

    @media (max-width: $screen-xxxs) {
        padding: $indent-xxl 0 0;

        .card--simple {
            .form {
                margin-top: $indent-m;
            }
        }
    }

    @media (max-width: $screen-md) {
        .onboarding__content {
            .form__item {
                .cv__radio {
                    display: flex;
                    flex-direction: column;
                    gap: $indent-hor;
                    padding: $indent-hor $indent-m;
                }
            }
        }
    }
}

.onboarding-no_text {
    margin-top: $indent-xxxl;
}

.mixed-case {
    text-transform: none;
}

.card--simple__image--no_text {
    margin-top: 5rem !important;
}

.form__item.form__item__label {
    margin-bottom: 0;
}