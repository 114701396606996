

.alert--box--success {
    @extend .alert--box;

    border-color: $color-success;
    color: $color-success;

    &:before {
        content: "\e080";
        color: $color-success;
    }
}
