
.header {
    height: $header-height;
    padding-top: $indent-l;
    background: $color-purple;

    .header__logo {
        position: relative;
        float: left;
        margin-top: 1.2rem;
        display: inline-block;
        z-index: 1;
        height: 36px;

        img {
            vertical-align: middle;
        }
    }

    #header__logo {
        content: var(--logo-image);
        width: 190px;
        height: 36px;
    }

    .blue__bg {
        width: 45%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: $color-blue;
    }

    @media (max-width: $screen-xs) {
        text-align: center;
        height: $header-height-mobile;
        padding-top: $indent-m;

        .blue__bg {
            width: 75%;
        }
    }
}
