

.button--cta {
    @extend .button;

    background: $color-purple;
    color: $color-default;

    &:hover,
    &:active,
    &:focus {
        background: $color-purple-dark;
        color: $color-default;
        text-decoration: none;
        border: none;

        i,
        .button__text {
            color: $color-default;
            text-decoration: none;
            border: none;
        }
    }

    &.disabled {
        background: $color-purple-disabled;

        &:hover {
            @include mi-drop-shadow-color(0, 0, 0, 0, #fff);
        }

        &:active,
        &:active:focus {
            cursor: pointer;

            @include mi-inner-shadow-color(0, 0, 0, 0, #fff);
        }

        &:focus {
            outline: none;

            @include mi-shadow-color(0, 0, 0, 0, $color-grey);

            &.active {
                text-decoration: none;
            }
        }
    }
}
