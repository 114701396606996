

.dropdown--large {
    @extend .dropdown;

    float:  none;
    display: block;
    margin: $indent-l 0 0;

    .dropdown__trigger {
        display: none;
        width: 100%;
        height: auto;
        padding: $indent-xs $indent-hor $indent-xs $indent-xxl+$indent-xl;
        line-height: $line-height-m;
        background: $color-default;
        border-radius: var(--border-radius);
        color: $color-text-lighter;
        font-weight: $font-weight-medium;
        border: 1px solid transparent;

        @include mi-transition();
        @include mi-drop-shadow(0, 1px, 3px, 0, 0.3);

        &:hover {
            @include mi-drop-shadow(0, 1px, 3px, 0, 0.5);
        }

        .dropdown__icon_down {
            line-height: $line-height-m;
            position: absolute;
            left: $indent-l
        }

        .dropdown__trigger__icon {
            position: absolute;
            left: $indent-hor + $indent-xl;
            top: 1.2rem;
            font-size: $font-size-l;
        }
    }

    .dropdown__content {
        position: relative;
        display: block;
        width: 100%;
        left: 0;
        margin-top: 0;
        border-radius: var(--border-radius);
        overflow: hidden;
        padding: $indent-hor;

        &:before,
        &:after {
            display: none;
        }

        ul {
            li {
                a {
                    color: $color-text;
                    font-style: normal;
                    padding-right: $indent-xxl;
                    font-size: $font-size-drawer-items;
                    line-height: $line-height-s;
                    .icons {
                        left: $indent-hor;
                    }
                }

                &:not(:last-child) {
                    a {
                        border-bottom: 1px solid $color-grey-80;
                    }
                }

                &.is-active {
                    a {
                        position: relative;
                        color: $color-purple;

                        &:after {
                            content: "\f12c";
                            position: absolute;
                            top: $font-size-xs;
                            right: $indent-m;
                            color: var(--color-theme);
                            font-family: $font-icons-alt;
                            font-size: $font-size-icon;
                        }
                    }
                }

                i {
                    display: none;
                }
            }
        }
    }

    &.dropdown--active {
        .dropdown__trigger {
            color: var(--color-theme);
            border-color: var(--color-theme);
        }
    }

    @media (max-width: $screen-xs) {
        margin-top: 0;

        .dropdown__trigger {
            padding-left: $indent-xl;
            padding-right: $indent-base;

            .dropdown__icon_down {
                right: $indent-s;
                left: unset;
            }

            .dropdown__trigger__icon {
                left: $indent-base;
            }
        }
    }
}
