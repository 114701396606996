
.button {
    position: relative;
    display: block;
    width: 100%; //Necessary for input buttons
    line-height: $line-height-m;
    margin: $indent-m 0;
    padding: $indent-s $indent-m;
    text-align: center;
    background: $color-bg;
    font-weight: $font-weight-medium;
    font-size: $font-size-default;
    border: none;
    color: $color-text;

    @include mi-no-appearance;
    @include mi-transition();

    &:hover {
        cursor: pointer;
    }

    &:active,
    &:active:focus {
        cursor: pointer;
    }

    &:focus {
        outline: none;

        &.active {
            text-decoration: none;
        }
    }

    &.inline-block {
        display: inline-block;
        width: auto;
        padding-left: $indent-xxl;
        padding-right: $indent-xxl;
    }

    &.inline-block--small {
        @extend .inline-block;

        padding-left: $indent-m;
        padding-right: $indent-m;
    }

    &.is-hidden {
        visibility: hidden;
    }

    &.is-loading {
        padding-left: 6rem;

        &.panel__button--icon_only {
            padding-left: $indent-m;
        }
    }

    .button__text {
        padding-left: $indent-s;
    }

    i
    {
        font-size: $font-size-icon-s;
        vertical-align: middle;
    }

    @media (max-width: $screen-xs) {
        padding-left: $indent-xs;
        padding-right: $indent-xs;

        &.inline-block {
            display: block;
            padding-left: $indent-xs;
            padding-right: $indent-xs;
        }

        .multibutton__container {
            flex-direction: column;
            gap: $indent-xs;
        }
    }
}

.button__panel {
    .button--cta {
        margin-top: 0;
    }
}

.svg-icon {
    vertical-align: sub;
}

.multibutton__container {
    display: flex;
    gap: $indent-s;

    .button--cta {
        margin: $indent-m 0;
    }
}

@media (max-width: $screen-md) {
    .multibutton__container {
        flex-direction: column;
        gap: $indent-action;

        .button--cta, .button--secondary {
            margin: 0;
        }
    }
}
