
$footer-height: 6.4rem;
$footer-bg: $color-blue;

.footer {
    height: $footer-height;
    margin: $indent-l 0 0;
    padding: $indent-base 0;
    background: $footer-bg;
    text-align: center;
    z-index: 1;

    a {
        display: inline-block;
        padding: 0 $indent-m;
        line-height: 1.7rem;
        font-size: $font-size-s;
        font-weight: $font-weight-regular;
        color: $color-cyan;
        border-right: 2px solid $color-default;
        text-decoration: none;
        vertical-align: top;

        &:hover,
        &:active {
            text-decoration: underline;
        }

        &:last-of-type {
            border-right: none;
        }
    }

    @media (max-width: $screen-sm) {
        height: auto;
        padding-bottom: 0;

        a {
            position: relative;
            display: block;
            padding: 0 0 $indent-xs;
            margin: 0 0 $indent-xs;
            border: none;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                display: block;
                width: $indent-xs;
                height: 2px;
                margin: auto;
                background: $color-text-light;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }

        .footer > div {
            margin: 0 !important;
            max-width: 100vw !important;
        }
    }

    @media (max-width: $screen-xs) {
        margin-top: $indent-base;
    }
}

.wrapper {
    min-height: 100vh;

    @include mi-flexbox();
    @include mi-flex-direction(column);
}

.content {
    @include mi-flex(1);

    &.popper-is-active
    {
        min-height: 100rem;
    }

}
