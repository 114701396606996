
.navigation_drawer {
    max-width: 250px;
    min-width: 250px;
    width: 100%;
    .header_hide {
        height: $indent-m;
    }



    .navigation {
        padding-top: $header-height-mobile;

        .main {
            padding: 0;

            a {
                font-size: $font-size-drawer-items;
                display: block;
                padding: $indent-s $indent-m;
            }

            i {
                padding-right: $indent-s;
            }

            &.flexapp__logo {
                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        height: 36px;
                    }

                    span {
                        margin-left: 1rem;
                    }
                }
            }

            .sub {
                padding: 0;

                a {
                    padding-left: $indent-l * 2;
                }

                &:first-child {

                }
            }
        }
    }

    hr {
        border: none;
        border-top: 1px solid $color-border-light;
    }
}

.logout-button-wrapper {
    position: fixed;
    top: 100vh;
    @media (max-height: 600px) {
        top: 100px;
        position: relative;
    }
    width: 100%;
    transform: translateY(-100%);
    .logout-button {
        cursor: pointer;
        width: 100%;
        background-color: #E84F14;
        height: 8rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-logout {
            font-size: 1.8rem;
        }
        span {
            font-family: var(--font-header);
            font-size: $font-size-h3;
            margin: 0;
            margin-left: 1rem;
        }
    }
}

[role="navigation"] {
    > div {
        z-index: 8999 !important;

        + div {
            z-index:  8998 !important;

            + div {
                z-index: 0 !important;
            }
        }
    }
}
