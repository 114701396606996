// get the page ready
*,
:after,
:before {
    outline: none;
    box-sizing: border-box;
}

html {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
    height: 100% !important;
}

body {
    height: 100% !important;
}

html {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
}

body {
    min-width: 32rem;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: var(--font-default);;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
    background: $color-background;
    line-height: $line-height-default;
    //overflow-y: auto;
}

:root {
    // active
    // These values are default values. They can be overwritten by the values in the config file.
    --color-theme: $color-theme;
    --color-theme-light: $color-theme-light;
    --color-theme-dark: $color-theme-dark;
    --color-cta: $color-cta;
    --color-cta-text: var(--color-cta);
    --color-cta--disabled: $color-cta--disabled;
    --border-radius: $border-radius-l;
    --font-default: "Fira Sans", sans-serif;
    --font-header: "Oswald", sans-serif;
    --color-gradient-start: $color-gradient-start;
    --color-gradient-middle: $color-gradient-middle;
    --color-gradient-end: $color-gradient-end;

    --font-size-default: 1.6rem;
    --font-size-h1: 4rem;
    --font-size-h2: 3.2rem;
    --font-size-h3: 2.4rem;

    @media (max-width: $screen-xs) {
        --font-size-h1: 3.2rem;
        --font-size-h2: 1.8rem;
        --font-size-h3: 1.8rem;
        --font-size-default: 1.4rem;
      }
}

div {
    position: relative;
}

img {
    border: none; //Prevent IE form adding borders
}

h1,
.heading-h2 {
    margin: $indent-l 0;
    font-family: var(--font-header);
    font-size: $font-size-h1;
    text-transform: uppercase;
    letter-spacing: 1px;
}

h2,
.heading-h2 {
    margin: $indent-l 0;
    line-height: 1.33;
    font-family: var(--font-header);
    font-size: $font-size-h2;
    text-transform: uppercase;
}

h3 {
    font-size: $font-size-h3;
    line-height: $font-size-xl;
    font-weight: bold;
    margin: $line-height-m 0;
    font-family: var(--font-header);
    text-transform: uppercase;
}

h4 {
    margin: $line-height-m 0;
    font-family: var(--font-header);
    font-size: $font-size-h4;
    font-weight: $font-weight-regular;
}

p {
    margin: 0 0 $line-height-m;
}

.text--underline {
    margin: 0;
    text-decoration: underline;
}

.text--link {
    text-decoration: underline;
    cursor: pointer;
    font-style: italic;
}

.text-muted {
    color: $color-grey-60;
}

th,
td {
    padding: 0 $indent-m 0 0;
    font-family: var(--font-default);;
    font-size: $font-size-s;
    text-align: left;
    font-weight: $font-weight-medium;
    line-height: $line-height-default;
}

p,
span,
li,
h1,
h2,
h3,
h4 {
    a {
        font-weight: $font-weight-medium;
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }
}

a {
    text-decoration: none;
    color: inherit;

    @include mi-transition(0.1s);

    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
        outline: none;
        text-decoration: none;
    }
}

input,
select,
option,
button {
    &:focus {
        outline: none;
    }
}

button {
    font-family: var(--font-default);;
    font-weight: $font-weight-semibold !important;
}

label,
p {
    a {
        text-decoration: underline;
        font-style: italic;

        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    @media (max-width: $screen-xs) {
        br {
            display: none;
        }
    }
}

strong {
    font-weight: $font-weight-semibold;
}

.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.margin-top {
    margin-top: $indent-m;
}

.margin-right {
    margin-right: $indent-m;
}

.no-margin {
    margin: 0 !important;
}

.spacer {
    padding: 0 0 $indent-l;
}

.spacer-s {
    padding: $indent-s;
}

.spacer-hr {
    margin: $indent-l 0;
    border-top: 1px solid $color-border-light;
}

.spacer-button {
    display: block;
    width: 100%;
    height: $indent-hor;
    margin: $indent-l 0 0;

    @media (max-width: $screen-xs)
    {
        display: none;
    }
}

.truncated
{
    float: right;
    width: 70rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: $screen-lg)
    {
        max-width: 55rem;
    }

    @media (max-width: $screen-md)
    {
        max-width: 40rem;
    }

    @media (max-width: $screen-sm)
    {
        max-width: 22rem;
    }

    @media (max-width: $screen-xs)
    {
        max-width: 20rem;
    }
}

.truncated--off {
    @extend .truncated;

    white-space: pre-wrap;
}

//Give element a different color:
.theme {
    color: var(--color-theme) !important;
}
.purple {
    color: $color-theme !important;
}

.blue {
    color: $color-theme-light !important;
}

.white {
    color: $color-default !important;
}

.red {
    color: $color-alert !important;
}

.yellow {
    color: $color-alt !important;
}

.green {
    color: $color-cta !important;
}

.grey {
    color: $color-grey-40 !important;
}

//Add line breaks to element so that formatting is preserved
.line-breaks {
    white-space: pre-line;
}

.table-repsonsive {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

::selection {
    background: var(--color-theme-light);
}

::-webkit-input-placeholder {
    color: $color-text-light;
    font-weight: $font-weight-medium;
    font-style: italic;
}
::-moz-placeholder {
    color: $color-text-light;
    font-weight: $font-weight-medium;
    font-style: italic;
}
:-ms-input-placeholder {
    color: $color-text-light;
    font-weight: $font-weight-medium;
    font-style: italic;
}
:-moz-placeholder {
    color: $color-text-light;
    font-weight: $font-weight-medium;
    font-style: italic;
}

::-ms-expand {
    display: none !important;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

//Remove chrome's ugly yellow background for suggestions:
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

@media (min-width: $screen-xs) {
    .sticky_position {
        position: relative !important;
        position: sticky !important;
        top: 0;
    }
}

.img-smiley-success {
    content: var(--smiley-success);
}

.img-smiley-inspect {
    content: var(--smiley-inspect);
}

.img-smiley-search {
    content: var(--smiley-search);
}

.img-smiley-edit {
    content: var(--smiley-edit);
}

.img-smiley-404 {
    content: var(--smiley-404);
}

.hidden {
    display: none;
}

.word-wrap {
    word-break: break-word;
}

.transparent {
    background: transparent !important;
}

.no-border {
    border: none !important;
}

.no-shadow {
    box-shadow: none !important;
}