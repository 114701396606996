

.button--danger {
    @extend .button--alt;

    margin: $indent-l auto 0;
    color: $color-alert;

    &:hover,
    &:active {
        color: darken($color-alert, 10%);
    }

    @media (max-width: $screen-xs) {
        margin: $indent-s auto;
    }

    &.delete--profile {
        background: none;
        color: $color-alert;
        border: 1px solid $color-alert;
        box-shadow: none;
    }
}
