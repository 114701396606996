

.panel--events {
  @extend .panel;
  box-shadow: none !important;
  margin-top: 0;

  .panel__header {
    .text__replacement {
      font-family: $font-header;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.82px;
      line-height: 30px;
      text-align: right;
    }

    .button--cta {
      &.panel__button--icon_only {
        top: -0.5rem;
        right: -$indent-hor;
      }
    }
  }

  .panel__body {
    >.spacer-hr:first-of-type {
      border-top-width: 0;
      margin-top: 0;
    }
  }

  @media (max-width: $screen-sm) {
    .panel__header .button--cta {
      right: -$indent-s;
      &.panel__button--icon_only {
        top: -$indent-xs;
      }
    }
  }

  @media (max-width: $screen-xs) {
    .panel__header .button--cta {
       &.panel__button--icon_only {
        top: -1.5rem;
        right: -$indent-s;
      }
    }
  }
}
