//Breakpoints
$screen-xxxs: 360px;
$screen-xxs: 480px;
$screen-xs: 575px;
$screen-sm: 767px;
$screen-md: 991px;
$screen-lg: 1199px;

//Layout
$indent-action: 0.3rem;
$indent-xxs: 0.5rem;
$indent-xs: 1rem;
$indent-s: 1.5rem;
$indent-m: 2rem;
$indent-l: 3rem;
$indent-hor: 4rem;
$indent-xl: 5rem;
$indent-button: 6rem;
$indent-xxl: 7rem;
$indent-xxxl: 9.5rem;

$indent-base: $indent-s;

$header-height: 12rem;
$header-height-mobile: 10rem;
$card-height: 58rem;
$form-input-height: 3.5rem;

//Borders
$border-radius: 2px;

// This large border radius is a default value. It can be overwritten by the value in the config file.
$border-radius-l: 4px;
$border-radius-xl: 10px;
$border-radius-xxl: 16px;

//Line heights
$line-height-xs: 1rem;
$line-height-default: 1.5;
$line-height-s: 2.2rem;
$line-height-m: 2.4rem;
$line-height-xl: 3rem;

//Colors
$color-default: #fff; //White

$color-alert: #ff5427; //Red
$color-alert-hover: darken($color-alert, 0.1); //Darker red
$color-events-red: #ff4242;
$color-alt: #f7b732; //Yellow
$color-alt-dark: #8b572a; // Brown

$color-grey: #000; //Almost black
$color-grey-80: transparentize($color-grey, 0.2);
$color-grey-60: transparentize($color-grey, 0.4);
$color-grey-40: transparentize($color-grey, 0.6);
$color-grey-20: transparentize($color-grey, 0.8);
$color-grey-10: transparentize($color-grey, 0.9);
$color-grey-5: #f2f3f4;
$color-grey-alt: #4a4a4a;
$color-default-40: transparentize($color-default, 0.6);

// Colors for the new design
$color-purple: #601ec8;
$color-purple-dark: #40077f;
$color-purple-disabled:#9771d5;
$color-blue: #051457;
$color-background: #eae0f7;
$color-background-light: #f5effb;
$color-cyan: #77fced;

// These colors are default values. They can be overwritten by the values in the config file.
$color-theme: #735cc5; //Purple
$color-theme-light: #4aabe2; //Light blue
$color-theme-dark: #034ea2; //Dark blue
$color-cta: #4abcac; //Green
$color-cta--disabled: #bae5df;

$color-gradient-start: #453194;
$color-gradient-middle: #685fba;
$color-gradient-end: #79b7e6;

$color-bg: $color-default;
$color-bg-darker: $color-grey-5;
$color-text: $color-grey;
$color-text-lighter: $color-grey-alt;
$color-text-light: $color-grey-40;
$color-link: $color-theme-light;
$color-border: $color-grey;
$color-border-light: $color-grey-20;
$color-success: $color-cta;



//Fonts
$font-default: "Fira Sans", sans-serif;
$font-header: "Oswald", sans-serif;
$font-icons: "simple-line-icons";
$font-icons-alt: "Material Design Icons";

//Font sizes
$font-size-xs: 1.2rem;
$font-size-s: 1.4rem;
$font-size-default: var(--font-size-default);
$font-size-l: 2rem;
$font-size-xl: 2.4rem;
$font-size-h1: var(--font-size-h1);
$font-size-h2: var(--font-size-h2);
$font-size-h3: var(--font-size-h3);
$font-size-h4: 1.6rem;
$font-size-drawer-items: 1.6rem;



$font-size-icon: 2.4rem;
$font-size-icon-s: 2rem;
$font-size-icon-xs: 1.6rem;



//Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600; //= 500 for buttons..
$font-weight-bold: 700;

//Borders
$border-radius: 3px;

$images-path: "/assets/images/";
$font-path: "/assets/fonts/";

